import React from 'react';
import './Blogs.css';
import './Post.css';
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Blogsheader from "./Blogsheader";
// import { useParams } from "react-router-dom";
import Footer from "./Footer";
import { Button } from '@mui/material';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
 // import { Helmet } from 'react-helmet';
// import Box from "@mui/material/Box";
// import LinearProgress from "@mui/material/LinearProgress";
 
var axios = require("axios");

const About = () => {
 
  // const [pageData, setPageData] = useState();
  // const [progress, setProgress] = React.useState(0);
//   useEffect(() => {
//   const timer = setInterval(() => {
//     setProgress((oldProgress) => {
//       if (oldProgress === 100) {
//         return 0;
//       }
//       const diff = Math.random() * 10;
//       return Math.min(oldProgress + diff, 100);
//     });
//   }, 500);

//   return () => {
//     clearInterval(timer);
//   };
// }, []);

  // useEffect(async() => {
  //   const aboutPageData = await axios.get(
  //     "https://api.wundrfly.com/wp-json/wp/v2/pages/74"
  //   );
  //   console.log(aboutPageData);
  //   setTimeout(() => {
  //     setPageData(aboutPageData.data);
  //   }, 100);
     
  // }, [])


 
  return (
    <div> 
    <Blogsheader></Blogsheader>
    <div className="container-fuild">
    <div className="container">
    <div className='row pt-5 pb-5 vA forMobileVer'>
    <div className='col-md-6'>
    <h1 className="customheaderTitle">Navigate the Skies with Ease
    Find the Best Deals on Flights Worldwide
    </h1>
    
    <a href="https://wundrfly.com/"><Button variant='contained' className="btnOnHover" sx={{color: '#fff', textTransform: 'inherit', bgcolor: '#030d4e'}}><SearchSharpIcon className='searchIcon' />Search Now</Button></a>
    </div>
    <div className='col-md-6'>
    <img className="aboutusImage" src='aboutus.png' alt='about us' />
    </div>
    </div>
    </div>
    </div>
    <div className="container pt-4 pb-4 wp-pages">
    <div className='text-center'>
    <h3 className='subheading'>Welcome to Wundrfly, your ultimate destination for finding the best deals on flights. We understand that planning a trip can be overwhelming and time-consuming, which is why we have created a simple and user-friendly platform to make your search for flights as easy and stress-free as possible.  </h3>
    <h6 className='subLine'>Our advanced search engine allows you to compare prices from multiple airlines and travel agencies, ensuring you find the best deal on your flight. With just a few clicks, you can find the perfect flight for your budget and travel needs.  </h6>
    <a href="https://wundrfly.com/"><Button variant='contained' className="btnOnHover" sx={{color: '#fff', textTransform: 'inherit', bgcolor: '#030d4e'}}><SearchSharpIcon className='searchIcon' />Search Now</Button></a>
    </div>
    </div>
    <div className='container-fluid bgstyle wp-pages'>
    <div className='container'>
    <h3 className='subheading'>Our platform features a wide range of options, including: </h3>
     <ul className='ulstyle'>
     <li>One-way and round-trip flights</li>
     <li>Direct and connecting flights</li>
     <li>Economy, business, and first-class options</li>
     <li>Flexible date search</li>
     <li>Advanced filtering options</li>
     <li>24/7 customer support</li>
     </ul>
    
    </div>
    <center style={{marginTop: '50px'}}><a href="https://wundrfly.com/"><Button variant='contained' className="btnOnHover" sx={{color: '#fff', textTransform: 'inherit', bgcolor: '#030d4e'}}><SearchSharpIcon className='searchIcon' />Search Now</Button></a>
    </center>
    </div>  
    <div className='container wp-pages' style={{marginTop: '50px', textAlign: "center", paddingBottom: '50px'}}>
    <p>We are constantly updating our database to ensure that we offer the most current information and deals on flights. Our team of travel experts is also on hand to assist you with any questions or concerns you may have.</p>
    <p>In addition to flights, we also offer a variety of travel-related services such as hotel bookings, car rentals, and vacation packages. With our one-stop-shop approach, you can easily plan and book your entire trip with us.</p>
    <p>We also offer a loyalty program for our frequent flyers, where you can earn points and redeem them for discounts on future bookings.</p>
    <p>At wundrfly.com, we pride ourselves on providing our customers with a seamless, convenient, and cost-effective way to book their flights. We hope you will give us the opportunity to help plan your next adventure.</p>
    <p>Thank you for choosing wundrfly.com for your travel needs, and happy travels!</p>
   
    <center style={{marginTop: '50px'}}><a href="https://wundrfly.com/"><Button variant='contained' className="btnOnHover" sx={{color: '#fff', textTransform: 'inherit', bgcolor: '#030d4e'}}><SearchSharpIcon className='searchIcon' />Search Now</Button></a>
    </center>
    </div>
   
  
     
   
       <Footer></Footer>
    </div>
  )
}

export default About;
